import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  signInWithEmailAndPassword   } from 'firebase/auth';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox ,Alert ,Collapse} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { auth } from '../../../hooks/firebase';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState(false);
  const [alerterror, setAlerterror] = useState('');

  function validateEmail(email) {
    // regular expression to validate email format
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // check if email is valid
    if (regex.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async  () => {
    if (username.trim() === "") {
      setAlert(true);
      setAlerterror('Please enter a username.');
      return;
    }
    if (username.includes(" ")) {
      setAlert(true);
      setAlerterror('Username cannot include spaces.');
      return;
    } 
    // validate email
    if (!validateEmail(email)) {
      setAlert(true);
      setAlerterror('Please enter a valid email address.');
      return;
    }
    if (password.trim() === "") {
      setAlert(true);
      setAlerterror('Please enter a password for your account.');
      return;
    }
    try {
      const response = await fetch('https://widget.bcall.us/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username,email:email,password:password,ref:"dashboard"})
        // body: {"username":username,"email":email,"password":password}
      })
      const data = await response.json();
      if(!data.status)
      {
        setAlert(true);
        setAlerterror(data.message);
      }else{
        navigate(`/congratulations?user=${username}`)
      }
    } catch (error) {
  
    }
       
  };

  return (
    <>
      <Stack spacing={3}>
      <TextField name="username" label="Username" onChange={(e)=>setUsername(e.target.value)} onClick={(e)=>setAlert(false)}/>
        <TextField name="email" label="Email address" onChange={(e)=>setEmail(e.target.value)} onClick={(e)=>setAlert(false)}/>

        <TextField
        onChange={(e)=>setPassword(e.target.value)}
        onClick={(e)=>setAlert(false)}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        
        
      </Stack>
      <Collapse in={alert}>
      <Alert severity="error">{alerterror}</Alert>
      </Collapse>
      
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
      Create account
      </LoadingButton>
    </>
  );
}
