import { useNavigate,useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import Avatar from '@mui/material/Avatar';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button,Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { SignupForm } from '../sections/auth/signup';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  let navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const linka = urlParams.get('user')
  return (
    <>
      <Helmet>
        <title> Sign up | bcall.us </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        

        <Container maxWidth="sm">
          <StyledContent>
          <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h3" sx={{m: 2}}>
          Congratulations!
          </Typography>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <EmojiEventsIcon />
          </Avatar>
          Address to call
        <Link href={`https://bcall.us/${linka}`} typography="h5" target="_blank">https://bcall.us/{linka}</Link>
        
          <Button variant="contained" color="primary"  sx={{textTransform: "none" ,my: 2}} onClick={() => {
                   navigate('/login')
                    }}>
            Log in
          </Button>
        </Box>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
