import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";

import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
import { auth } from '../hooks/firebase';
// sections
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';


// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [click, setClick] = useState(0);
  const [vm, setVm] = useState(0);
  const [call, setCall] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(()=>{

    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          // ...
          auth.currentUser.getIdToken(true).then((idToken) => {
              // console.log(idToken);
              const headers = { 'Content-Type': 'application/json' ,'authtoken':idToken}
              fetch('https://widget.bcall.us/api/dashboardstat', { headers })
              .then((response) => response.json())
              .then(data => {
                Object.entries(data).forEach(entry => {
                  const [key, value] = entry;
                  switch(value._id) {
                    case 'vm':
                      setVm(value.count);
                      break;
                    case 'extension':
                      setCall(value.count);
                      break;
                    case 'Click':
                      setClick(value.count);
                      break;
                    default:
                      // code block
                  }
                  
                });
                  
              });




          }).catch((error) => {
            console.log("Error getting auth token");
          });
          // console.log("uid", uid)
        } else {
          // User is signed out
          // ...
          console.log("user is logged out");
          navigate("/login");	
          
        }
      });
     
}, [])

  return (
    <>
      <Helmet>
        <title> Dashboard |  bcall.us </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Clicks" total={click} icon={'clarity:cursor-hand-click-line'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Calls" total={call} color="warning" icon={'fluent:call-24-regular'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Voicemails" total={vm} color="info" icon={'fluent:voicemail-24-regular'} />
          </Grid>

        </Grid>
      </Container>
    </>
  );
}
