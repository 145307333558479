// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_jRaajAb9r6BZ4TwEJ3n9duPKOXt6ZW4",
  authDomain: "bcall-2dd44.firebaseapp.com",
  projectId: "bcall-2dd44",
  storageBucket: "bcall-2dd44.appspot.com",
  messagingSenderId: "432036300551",
  appId: "1:432036300551:web:893a7ee9fd832d44fbe7a2",
  measurementId: "G-34SP4GV1LT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export default app;