import { Helmet } from 'react-helmet-async';
import {useEffect,useState}  from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  CardContent,
  Divider,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Box
 
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PreviewIcon from '@mui/icons-material/Preview';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CodeIcon from '@mui/icons-material/Code';
import { styled } from '@mui/material/styles';
import MuiGrid from '@mui/material/Grid';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useAuth } from "../contexts/AuthContext";
import { auth } from '../hooks/firebase';


const Grid = styled(MuiGrid)(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));


export default function UserPage() {
  const navigate = useNavigate();
  const { token ,name} = useAuth();
  const [value, setValue] = useState(0);
  const [widget,setWidget] = useState("");
  const [voice,setVoice] = useState("none");
  const [lang, setLang] = useState('');
  const [code, setCode] = useState('none');
  const [phone, setPhone] = useState('');
  const [phoneadv, setPhoneAdv] = useState('');
  const [direction, setDirection] = useState('');
  const [message, setMessage] = useState('');
  const [tokenn, setTokenn] = useState('');
  const [langdir, setLangdir] = useState('ltr');
  const [phoneview, setPhoneview] = useState('none');
  const [phoneadvview, setPhoneAdvview] = useState('none');
  const [extensionsview, setExtensionsview] = useState('none');
  const [actionwidget, setActionwidget] = useState('');
  const [savebutton, setSavebutton] = useState('Save');
  const codestr=`<script src="https://widget.bcall.us/widget/${name}.js"></script>`

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        auth.currentUser.getIdToken(true).then((idToken) => {
            // console.log(idToken);
            setTokenn(idToken);
            const headers = { 'Content-Type': 'application/json' ,'authtoken':idToken}
        fetch('https://widget.bcall.us/api/settings', { headers })
        .then((response) => response.json())
        .then(data => {
            // console.log(data.voice.lang);
            setPhone(data.widget.phone);
            setPhoneAdv(data.widget.phoneadv);
            setDirection(data.widget.direction);
            setActionwidget(data.widget.action);
            switch(data.widget.action) {
              case "Phone":
                setPhoneview("");
                break;
              case "PhoneAdv":
                setPhoneAdvview("");
                break;
              case "Extensions":
                setExtensionsview("");
                break;
              default:
                  // code block
                  // <Tab icon={<AccessTimeIcon />} label="Operating Hours" />
            }
            if(data.widget.action === 'Phone')
            {
                setPhoneview("");
            }
            setMessage(data.voice.message);
            setLang(data.voice.lang);
            if(data.voice.lang === "he-IL-HilaNeural")
            {
                setLangdir("rtl");
            }

        });

        }).catch((error) => {
          console.log("Error getting auth token");
        });
        // console.log("uid", uid)
      } else {
        // User is signed out
        // ...
        console.log("user is logged out");
        navigate("/login");	
        
      }
    });

      // console.log(token);
      

  }, []);

  const sendsettings = () => {
    setSavebutton('Saving...');
    const headers = { 'Content-Type': 'application/json' ,'authtoken':tokenn}
        fetch('https://widget.bcall.us/api/settings', { headers, method: 'POST',body: JSON.stringify({ widget: {"direction":direction,"phone":phone,"phoneadv":phoneadv,"action":actionwidget},"voice":{"lang":lang,"message":message} })})
        .then((response) => response.json())
        .then(data => {
            // console.log(data.voice.lang);
            setSavebutton('Save');
        });
  };

  const handleChangelang = (event) => {
    setLang(event.target.value);
    if(event.target.value === "he-IL-HilaNeural")
    {
        setLangdir("rtl");
    }else{
      setLangdir("ltr");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch(newValue) {
      case 0:
        setWidget("");
        setVoice("none");
        setCode("none");
        break;
      case 1:
        setWidget("none");
        setVoice("");
        setCode("none");
        break;
      // case 2:
      //  setWidget("none");
      //  setVoice("none");
      //  setCode("none");
      //  break;  
      case 2:
        setWidget("none");
        setVoice("none");
        setCode("");
        break;  
        default:
          // code block
          // <Tab icon={<AccessTimeIcon />} label="Operating Hours" />
    }
  };

  return (
    <>
      <Helmet>
        <title> Settings | Bcall.us </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </Stack>
        <Grid container spacing={3}>
        <Grid item xs={8}  >
        <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example">
        <Tab icon={<PreviewIcon />} label="Widget" />
        <Tab icon={<RecordVoiceOverIcon />} label="Voice Message" />
        <Tab icon={<CodeIcon />} label="installation" />
        </Tabs>
        </Grid>
        <Grid item xs={8}  display={widget}>
        <Card >
        <CardContent>
        <FormControl>
        <FormLabel id="demo-form-control-label-placement">Widget Direction:</FormLabel>
        <RadioGroup
        row
        aria-labelledby="demo-form-control-label-placement"
        name="position"
        defaultValue="Right"
        value={direction}
        onChange={(e) => setDirection(e.target.value)}
        >
        
       <FormControlLabel
          value="Left"
          control={<Radio />}
          label="Left"
          labelPlacement="start"
        />
        <Divider orientation="vertical" flexItem>
    -
  </Divider>
        <FormControlLabel value="Right" control={<Radio />} label="Right" />
      </RadioGroup>
      </FormControl>
      <Divider />
      <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Widget action click</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e) => {
          switch(e.target.value) {
            case "Phone":
              setPhoneview("");
              setPhoneAdvview("none");
              setExtensionsview("none");
              setActionwidget(e.target.value)
              break;
            case "PhoneAdv":
              setPhoneAdvview("");
              setPhoneview("none");
              setExtensionsview("none");
              setActionwidget(e.target.value)
              break;
            case "Extensions":
              setExtensionsview("");
              setPhoneview("none");
              setPhoneAdvview("none");
              setActionwidget(e.target.value)
              break;
            case "Voicemail":
              setPhoneview("none");
              setPhoneAdvview("none");
              setExtensionsview("none");
              setActionwidget(e.target.value)
              break;
            default:
                // code block
                // <Tab icon={<AccessTimeIcon />} label="Operating Hours" />
          }
        }}
        value={actionwidget}
      >
        <FormControlLabel value="Phone" control={<Radio />} label="Phone(Basic)" />
        <FormControlLabel value="PhoneAdv" control={<Radio />} label="Phone(Advanced)" />
        <FormControlLabel value="Extensions" control={<Radio />} label="Chrome Extensions" />
        <FormControlLabel value="Voicemail" control={<Radio />} label="Voicemail" />
      </RadioGroup>
    </FormControl>
    
      <Grid item xs={8} display={phoneview}>
      <FormLabel id="demo-form-control-label-placement">Phone number:</FormLabel>
      <div>
      <TextField 
          id="phone-input"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        </div>
        </Grid>

        <Grid item xs={8} display={phoneadvview}>
      <FormLabel id="demo-form-control-label-placement">Phone number:</FormLabel>
      <div>
      <TextField 
          id="phone-input"
          value={phoneadv}
          onChange={(e) => setPhoneAdv(e.target.value)}
        />
        </div>
        </Grid>

        <Grid item xs={8} display={extensionsview}>
      <FormLabel id="demo-form-control-label-placement">Click install:</FormLabel>
        <div>
        <a href="https://chrome.google.com/webstore/detail/bcall-easy-simple-to-call/fmdiblfklkbggefkofdmbgejmonlgkma" target="_blank">
      <img src="/extension.png"  />
    </a>
        </div>
        </Grid>

        </CardContent>
        </Card>
        </Grid>
        <Grid item xs={8}  display={code}>
        <Card >
        <CardContent>
        Paste this code snippet just before the body tag
        <TextField sx={{  mb:2 }}
          disabled
          id="filled-disabled"
          value={codestr}
          variant="filled"
          fullWidth
        />
        <CopyToClipboard text={codestr}
          onCopy={() => alert("Copied")}>
          <button >Copy to clipboard</button>
        </CopyToClipboard>
        
        </CardContent>
        </Card>
        </Grid>

        <Grid item xs={8}  display={voice}>
        <Card >
        <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small">Languages</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        label="languages"
        onChange={handleChangelang}
        value={lang}
      >
        <MenuItem value={"en-US-SaraNeural"}>English</MenuItem>
        <MenuItem value={"he-IL-HilaNeural"}>Hebrew</MenuItem>
      </Select>
      </FormControl>
      <div>
      <TextField dir={langdir}
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={6}
          value={message}
          sx={{  }}
          onChange={(e) => setMessage(e.target.value)}
        />
        </div>
    
        </CardContent>
        </Card>
        </Grid>

        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
  <Grid item xs={7}> </Grid>
  <Grid item xs={4} >
  <Button  variant="contained" color="success" onClick={() =>sendsettings()}>
        {savebutton}
        </Button>
        </Grid>
  </Grid>
        </Grid>
      </Container>
    </>
  );
}