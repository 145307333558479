import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  signInWithEmailAndPassword   } from 'firebase/auth';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox ,Alert ,Collapse} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { auth } from '../../../hooks/firebase';
// components
import Iconify from '../../../components/iconify';
import mixpanel from 'mixpanel-browser';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState(false);
  const [alerterror, setAlerterror] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            // navigate("/home")
            console.log(user.displayName);
            mixpanel.init('f4710b9de9fbf50c638ae49451c3261f');
            mixpanel.identify(user.displayName)
            mixpanel.track('Signed In', {
              'Signup_Type': 'dashboard'
            });
            mixpanel.people.increment("Signin_Dashboard_count", 1);
            navigate('/', { replace: true });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // console.log(errorCode, errorMessage)
            setAlert(true);
            
              setAlerterror('Please check the Email or Password');
            
           
        });
    // navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(e)=>setEmail(e.target.value)} onClick={(e)=>setAlert(false)}/>

        <TextField
        onChange={(e)=>setPassword(e.target.value)}
        onClick={(e)=>setAlert(false)}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>
      <Collapse in={alert}>
      <Alert severity="error">{alerterror}</Alert>
      </Collapse>
      
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
