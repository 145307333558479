import { createContext, useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

import {auth} from "../hooks/firebase";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [token, setToken] = useState("");

  function register(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
      user.getIdToken(true).then((idToken) => {
      setToken(idToken);
 
      const headers = { 'Content-Type': 'application/json' ,'authtoken':idToken}
        fetch('https://widget.bcall.us/api/dashboard', { headers })
        .then((response) => response.json())
        .then(data => {
            setName(data.name);
            setEmail(data.email);
        });

      }).catch((error) => {
        console.log("Error getting auth token");
      });
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    register,
    email,
    name,
    token
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}