import React, { useState, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './hooks/firebase';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import RecordsPage from './pages/RecordsPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import SettingsPage from './pages/SettingsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import SignupPage from './pages/SignupPage';
import Congratulations from './pages/Congratulations';



 

// ----------------------------------------------------------------------

export default function Router() {
  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          // const uid = user.uid;
          // ...
          // console.log("uid", uid)
        } else {
          // User is signed out
          // ...
          // console.log("user is logged out");
          // <Navigate to="/dashboard" replace true />
        }
      });
     
}, [])


  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'records', element: <RecordsPage /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'signup',
      element: <SignupPage />,  
    },
    {
      path: 'congratulations',
      element: <Congratulations />,  
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
