import { Helmet } from 'react-helmet-async';
import { useState,useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  Box
} from '@mui/material';
import {  DataGrid  } from '@mui/x-data-grid';
import { auth } from '../hooks/firebase';
import CallIcon from '@mui/icons-material/Call';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';






export default function UserPage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [token, setToken] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  function getURLParameters(parm) {
    var params = {};
    var queryString = parm;
    var parameterPairs = queryString.split('&');
  
    for (var i = 0; i < parameterPairs.length; i++) {
      var pair = parameterPairs[i].split('=');
      var paramName = decodeURIComponent(pair[0]);
      var paramValue = decodeURIComponent(pair[1] || '');
  
      if (params[paramName]) {
        params[paramName].push(paramValue);
      } else {
        params[paramName] = [paramValue];
      }
    }
  
    return params;
  }


  function getUrl(params) {
    // console.log(params);
    
    if(params.row.url)
    {
      const myArray = params.row.url.split("?");
      return myArray[0];
    }
    return "";
    
  }

  function getUtm_source(params) {
    // console.log(params);
    
    if(params.row.url)
    {
      const myArray = params.row.url.split("?");
      if(myArray.length>1)
      {
        if(getURLParameters(myArray[1]).utm_source)
        {
          return getURLParameters(myArray[1]).utm_source.toString();
        }else{
          return "";
        }
        
      }else{
        return "";
      }
      
      
    }
    return "";
    
  }

  function getUtm_medium(params) {
    // console.log(params);
    
    if(params.row.url)
    {
      const myArray = params.row.url.split("?");
      if(myArray.length>1)
      {
        if(getURLParameters(myArray[1]).utm_medium)
        {
          return getURLParameters(myArray[1]).utm_medium.toString();
        }else{
          return "";
        }
        
      }else{
        return "";
      }
      
      
    }
    return "";
    
  }

  function getUtm_campaign(params) {
    // console.log(params);
    
    if(params.row.url)
    {
      const myArray = params.row.url.split("?");
      if(myArray.length>1)
      {
        if(getURLParameters(myArray[1]).utm_campaign)
        {
          return getURLParameters(myArray[1]).utm_campaign.toString();
        }else{
          return "";
        }
        
      }else{
        return "";
      }
      
      
    }
    return "";
    
  }

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  } 
  
  function getCallerid(params) {
    
    if(isNumeric(params.row.callerid))
    {
      return params.row.callerid;
    }else{
      return "";
    }
    
    
  }

  function getRecord(params) {
    
    if(params.row.action==='vm')
    {
      if(params.row.duration==='0' || params.row.duration==='')
      {
        return "No voicemail message left";
      }
      /* eslint-disable jsx-a11y/media-has-caption */
      return (<audio controls preload="none"><source src={`https://widget.bcall.us/recorddownload?name=${params.row._id}&token=${token}`} /> type="audio/mpeg" </audio>)
    }
    if(params.row.action==='extension' || params.row.action==='sip')
    {
      if(params.row.duration==='0' || params.row.duration==='')
      {
        return "No recording of the call";
      }
      /* eslint-disable jsx-a11y/media-has-caption */
      return (<audio controls preload="none"><source src={`https://widget.bcall.us/recorddownload?name=${params.row._id}&token=${token}`} /> type="audio/mpeg" </audio>)
    }
    return "";
    
  }

  function getType(params) {
    
    if(params.row.action==='vm')
    {
      /* eslint-disable jsx-a11y/media-has-caption */
      return  <Tooltip title="Voicemail"><VoicemailIcon color="secondary"/></Tooltip>
    }

    if(params.row.action==='extension')
    {
      /* eslint-disable jsx-a11y/media-has-caption */
      return  <Tooltip title="Call"><CallIcon color="success"/></Tooltip>
    }
    if(params.row.action==='sip')
    {
      /* eslint-disable jsx-a11y/media-has-caption */
      return  <Tooltip title="Call"><CallIcon color="success" /></Tooltip>
    }
    if(params.row.action==='Click')
    {
      /* eslint-disable jsx-a11y/media-has-caption */ 
      return  <Tooltip title="Click"><AdsClickIcon /></Tooltip>
    }
    
    
  }


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        auth.currentUser.getIdToken(true).then((idToken) => {
            // console.log(idToken);
            setToken(idToken);
            const headers = { 'Content-Type': 'application/json' ,'authtoken':idToken}
        fetch('https://widget.bcall.us/api/records', { headers })
        .then((response) => response.json())
        .then(data => {
            setRecords(data);
        });

        }).catch((error) => {
          console.log("Error getting auth token");
        });
        // console.log("uid", uid)
      } else {
        // User is signed out
        // ...
        console.log("user is logged out");
        navigate("/login");	
        
      }
    });

      // console.log(token);
      

  }, []);
  const columns = [
    { field: '_id', headerName: 'ID', width: 90 ,hide: true},
    { field: 'stime', headerName: 'Date', width: 200 ,type:'dateTime' ,valueGetter: ({ value }) => value && new Date(value)},
    {
      field: 'action',
      headerName: 'Type',
      width: 75,
      renderCell: getType
    },
    {
      field: 'url',
      headerName: 'Page URL',
      width:200,
      valueGetter: getUrl
    },
    {
      field: 'url1',
      headerName: 'utm_source',
      width: 200,
      renderCell: getUtm_source
    },
    {
      field: 'url2',
      headerName: 'utm_medium',
      width: 200,
      renderCell: getUtm_medium
    },
    {
      field: 'url3',
      headerName: 'utm_campaign',
      width: 200,
      renderCell: getUtm_campaign
    },
    {
      field: 'callerid',
      headerName: 'CallerID',
      width: 150,
      valueGetter: getCallerid
    },
    {
      field: 'etime',
      headerName: 'Play record',
      width: 250,
      renderCell: getRecord
    }
  ];
  
  
  return (
    <>
      <Helmet>
        <title> Record | Bcall.us </title>
      </Helmet>
      <Dialog open={open}
        onClose={handleClose}>
      <DialogTitle>Set backup account</DialogTitle>
      </Dialog>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Records
          </Typography>
        </Stack>

        <Card>
        <Box sx={{ height: 650, width: '100%' }}>
        <DataGrid
        rows={records}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row._id}
      />
      </Box>
        </Card>
      </Container>
    </>
  );
}
